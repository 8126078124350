<template>
  <div class="manage-statistic">
    Statistic
  </div>
</template>

<script>

export default {
  name: 'ManageStatistic',
  components: {},
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped></style>
